<template>
    <div class="tgcss-w-full tgcss-pb-2 tgcss-px-1 font-IRANSans">

        <div class="tgcss-w-full tgcss-flex tgcss-items-center tgcss-mb-3">
            <span class="tgcss-text-sm tgcss-font-bold grow">{{title}}</span> 
            <router-link v-if="type == 'category-news'" :to="{ name: 'ApplicationNews' , params: { category_id: cat, } , params: { category_id: id, }, query: { subject: title }}" class="tgcss-opacity-70 tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800">مشاهده‌همه</router-link>                
            <router-link v-if="type != 'category-news'" :to="{ name: 'CategoryNews' , params: { category_id: cat, } , params: { category_id: id, }, query: { subject: title }}" class="tgcss-opacity-70 tgcss-text-xs !tgcss-no-underline !tgcss-text-gray-800">مشاهده‌همه</router-link>                
        </div>
        <slot v-if="loading">
            <div class="tgju-widget-content full-padding"> 
                <div class="text-center p-2">
                    <div class="widget-loading"></div>
                </div>
            </div>
        </slot>

        <ul v-if="!loading && slider.items" class="tgcss-flex tgcss-flex-col tgcss-overflow-y-auto tgcss-pb-2 tgcss-mb-0">
            <!-- ردیف اول -->
            <div class="tgcss-flex tgcss-w-full tgcss-flex-nowrap tgcss-mb-1">
                <router-link v-for="(item, index) in slider.items.data" :key="index" :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}" class="!tgcss-no-underline !tgcss-text-gray-800">
                    <div class="tgcss-flex tgcss-flex-col tgcss-border tgcss-border-gray-200 hover:tgcss-bg-gray-100 tgcss-w-36 tgcss-ml-2 tgcss-rounded-lg tgcss-overflow-hidden">

                        <div class="tgcss-cursor-pointer tgcss-contents tgcss-mb-4 tgcss-w-fit tgcss-min-w-fit  tgcss-ml-2">
                            <div class="tgcss-border-b tgcss-flex tgcss-items-center tgcss-justify-center tgcss-relative tgcss-overflow-hidden tgcss-h-[100px] tgcss-w-full">
                                <img class="tgcss-min-w-fit tgcss-w-fit tgcss-h-full" :src="item.image" :alt="item.title">
                                <div class="tgcss-absolute tgcss-flex tgcss-items-center tgcss-right-2 tgcss-font-semibold tgcss-text-white tgcss-bottom-2 tgcss-text-xs2 tgcss-z-10 ">
                                    <img class="tgcss-h-4 tgcss-w-4 tgcss-rounded-full tgcss-ml-1" :src="item.image" :alt="item.title">
                                    {{ item.category_title }}
                                </div>
                                <div class="tgcss-bg-gradient-to-b tgcss-from-transparent tgcss-to-black/80 tgcss-absolute tgcss-inset-0"></div>
                            </div>
                        </div>
                        <div class="tgcss-p-2">
                            <div class="tgcss-font-semibold tgcss-text-xss tgcss-leading-5 tgcss-line-clamp-2 tgcss-text-ellipsis tgcss-overflow-hidden tgcss-h-10 tgcss-mb-2">{{ item.title }}</div>
                            <div class="tgcss-text-xs2 tgcss-font-medium tgcss-text-gray-400">{{ item.jalali_created_at }}</div>
                        </div>

                    </div>
                </router-link>
            </div>
        
        </ul>
        
        


    </div>
</template>
    
<script>
    export default {
        name: 'newsRowSlide',
        props: ['cat', 'count', 'title', 'type', 'id'],
        components: {},
        data() {
            return {
                slider:[],
                slider_cat:0,
                loading:false
            }
        },
        mounted() {
            this.getSlider();
        },
        computed: {},
        methods: {
            isUpdatedLessThan24HoursAgo(updatedAt) {
                const updatedTimestamp = new Date(updatedAt).getTime() / 1000; // تبدیل تاریخ به تایم‌استمپ Unix
                const currentTimestamp = Date.now() / 1000; // تایم‌استمپ فعلی به ثانیه
                const oneDayInSeconds = 43200; // تعداد ثانیه‌ها در یک روز (24 ساعت)

                // بررسی اینکه آیا تاریخ وارد شده کمتر از 24 ساعت از تاریخ فعلی گذشته است
                return updatedTimestamp > currentTimestamp - oneDayInSeconds;
            },
            getSlider(){
                this.loading = true;
                $.ajax({
                    url: ( this.type == 'slug' ? 'https://api.tgju.org/v1/news/list?limit='+this.count+'&tag='+this.cat : 'https://api.tgju.org/v1/news/list?limit='+this.count+'&category_id='+this.cat ),
                    method: 'get',
                    complete: (res) => {
                        this.slider = res.responseJSON.response;  
                        this.slider_cat = Object.keys(this.slider.categories)[0];
                        this.loading = false;
                    }
                });
            },
        },
    }
</script>  